import "./SelectRoom.css";
import React, { useEffect, useState } from "react";
import Button from "../../UI/components/Button/Button";
import Image from "../../UI/components/Image/Image";
import Calendar from '../../assets/Calendar.svg';
import Traveller from "../../assets/Person.svg";
import DropdownComponent from "../../UI/components/Dropdown/Dropdown";
import moment from "moment";
const SelectRoomComponent = ({checkin , checkout, setBookingData, bookingData, handleDateShowModal}) => {
  const [isOpenRoom, setIsOpenRoom] = useState(false);
  const [updatedBooking, setUpdatedBooking] = useState([...bookingData]);
  const toggleRoomDropdown = () => {
    setIsOpenRoom(!isOpenRoom);
  };
  const [childAges, setChildAges] = useState(null);
  // const tomorrow = new Date();
  // tomorrow.setDate(tomorrow.getDate()+1);
  // const dayAftertomorrow = new Date();
  // dayAftertomorrow.setDate(dayAftertomorrow.getDate()+2);
  const changeBooking = () => {
    const bookingRequest =updatedBooking.map((room,index)=>{
      return {
        ...room,
        child_age: childAges[index],
      }
    })
    setBookingData(bookingRequest);
  }
  useEffect(()=>{
    const getChildAges = bookingData.map(item=>{
      if(item.child > 0)
      return item.child_age;
      else if(item.child === 0)
      return [0];
    });
    setChildAges([...getChildAges]);
  },[]);
  return (
    <div className="schedule-block">
      <div className="select-block">
      <div className="select-date" onClick={()=>handleDateShowModal()}>
                <div style={{ marginTop: '-5px'}}><Image src={Calendar} height='60px' width='60px'/></div>
                <div style={{ marginLeft: '-10px'}}>
                    <div className="select-date-title">Dates</div>
                    <div>{moment(checkin).format('MMM DD')} - {moment(checkout).format('MMM DD')}</div>
                </div>
            </div>
      </div>
      {/* <DropdownComponent options={[{value:'Travelers',label:'Travelers'}]}/> */}
      <div className="search-selector">
        <div className="select-date" onClick={toggleRoomDropdown}>
          <div style={{ marginTop: "-15px", marginLeft: "-10px" }}>
            <Image src={Traveller} height="80px" width="80px" />
          </div>
          <div style={{ marginLeft: "-15px" }}>
            <div className="select-date-title">Travellers</div>
            <div>
              {updatedBooking.length} room, {updatedBooking.reduce((partialSum, a) => partialSum + a.adult + a.child, 0)} travelers
            </div>
          </div>
        </div>
        <div className="dropdown-container">
          {isOpenRoom && (
            <div className="dropdown-content">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                Rooms:{" "}
                <div>
                  <button
                    type="button"
                    className="btn-rooms"
                    onClick={() =>{
                      const newRoom = {
                        "room_no": updatedBooking.length + 1,
                        "adult": 0,
                        "child": 0,
                        "child_age": [
                            0
                        ]
                    }
                    setChildAges([...childAges,[0]])
                    // const tempChildAges = [...childAges];
                    // tempChildAges.push([0]);
                    // setChildAges([...tempChildAges]);
                      setUpdatedBooking([
                        ...updatedBooking,
                        newRoom,
                      ])
                    }}
                  >
                    +
                  </button>
                  {updatedBooking.length}
                  <button
                    type="button"
                    className="btn-rooms"
                    onClick={() =>{
                        const tempBookingData = [...updatedBooking];
                        const e = tempBookingData.pop();
                        const tempChildAges = [...childAges];
                        tempChildAges.pop();
                        setChildAges([...tempChildAges]);
                        setUpdatedBooking([
                          ...tempBookingData
                        ]);
                    }}
                    disabled={updatedBooking.length === 1}
                  >
                    -
                  </button>
                </div>
              </div>
              {updatedBooking.map((item, index) => (<div className="mt-3">
              <div><b>Room {item.room_no}</b></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                Adults:{" "}
                <div>
                  <button
                    type="button"
                    className="btn-rooms"
                    onClick={() =>{
                        const updatedBookingData = [...updatedBooking];
                        updatedBookingData[index].adult +=1;
                        setUpdatedBooking([
                          ...updatedBookingData,
                        ])
                    }}
                  >
                    +
                  </button>
                  {item.adult}
                  <button
                    type="button"
                    className="btn-rooms"
                    onClick={() =>{
                        const updatedBookingData = [...updatedBooking];
                        updatedBookingData[index].adult -=1;
                        setUpdatedBooking([
                          ...updatedBookingData,
                        ])
                    }
                    }
                    disabled={item.adult === 1}
                  >
                    -
                  </button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: '10px'
                }}
              >
                <div className="flex-col"><div>Children:{" "}</div><div style={{fontSize:'12px', fontWeight:'400'}}>{'(Age 0 to 12)'}</div></div>
                <div className="mt-2">
                  <button
                    type="button"
                    className="btn-rooms"
                    onClick={() =>{
                        const updatedBookingData = [...updatedBooking];
                        updatedBookingData[index].child +=1;
                        // updatedBookingData[index].child_age = [updatedBookingData[index].child_age, 0];
                        setUpdatedBooking([
                          ...updatedBookingData,
                        ]);
                        if(updatedBooking[index].child ===1)
                          return;
                        const tempChildAges = [...childAges];
                        tempChildAges[index].push(0);
                        setChildAges([...tempChildAges]);
                    }}
                  >
                    +
                  </button>
                  {item.child}
                  <button
                    type="button"
                    className="btn-rooms"
                    onClick={() =>{
                        const updatedBookingData = [...updatedBooking];
                        updatedBookingData[index].child -=1;
                        if(updatedBookingData[index].child >= 1){
                          // updatedBookingData[index].child_age.pop();
                          const tempChildAges = [...childAges];
                          tempChildAges[index].pop();
                          setChildAges([...tempChildAges]);
                        }
                        setUpdatedBooking([
                          ...updatedBookingData,
                        ])
                    }
                    }
                    disabled={item.child === 0}
                  >
                    -
                  </button>
                </div>
              </div>
              <div className="d-flex flex-row flex-wrap">{updatedBooking.map((traveller,i)=>{

                    if(traveller.child && i === index)
                    return Array(traveller.child).fill(1).map((child, j) => <div style={{width:'30%', marginRight:'10px'}}><DropdownComponent options={[...Array(13).fill(1).map((inx,intj)=>({value: intj, label: intj}))]} label={`Child ${j+1} age`} title={childAges} setTitle={setChildAges} index={index} index2={j}/></div>);
                    })}</div>
              </div>))}
            </div>
          )}
        </div>
      </div>
      <a href="#accomodation" onClick={changeBooking}><Button label="Choose your room" /></a>
      <div className="msg">You won't be charged yet</div>
    </div>
  );
};
export default SelectRoomComponent;
